<template>
  <b-modal
    id="new-patient-modal"
    hide-header
    hide-footer
    size="xl"
    @hidden="onHidden"
    @show="onShow"
  >
    <div class="header d-flex flex-row justify-content-between">
      <p class="title">Novo cadastro</p>
      <v-close @click="$bvModal.hide('new-patient-modal')" />
    </div>
    <div class="body d-flex flex-column">
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="name">Nome completo</label>
            <input
              autocomplete="off"
              id="name"
              type="text"
              v-model="newPatientForm.name"
              class="form-control"
              @input="validateName('name')"
              :class="{ error: errors.patient_name }"
            />
            <div
              v-if="errors.patient_name"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="birthday">Data de nascimento</label>
            <input
              autocomplete="off"
              id="birthday"
              type="text"
              v-model="newPatientForm.birthday"
              class="form-control"
              ref="patient_birthday"
              @blur="validateBirthday()"
              placeholder="00/00/0000"
              v-mask="['##/##/####']"
              :class="{ error: errors.patient_birthday }"
            />
            <div
              v-if="errors.patient_birthday"
              class="custom-invalid-feedback"
            >
              {{ errors.patient_birthday }}
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="gender">Sexo <span class="help" v-if="!requiredField.patient_gender"> (opcional)</span></label>
            <multiselect
              id="gender"
              v-model="newPatientForm.gender"
              :options="genderOptions"
              track-by="value"
              label="name"
              :option-height="40"
              :showLabels="false"
              :searchable="false"
              placeholder="Selecione"
              class="with-border"
              ref="patient_gender"
              :class="{ error: errors.patient_gender }"
            >
              <template slot="caret">
                <div class="chevron">
                  <v-chevron-down />
                </div>
              </template>

              <template slot="noOptions"> Nenhuma opção </template>

              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
            <div
              v-if="errors.patient_gender"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="email">
              E-mail<span class="help" v-if="!requiredField.patient_email"> (opcional)</span>
            </label>
            <input
              autocomplete="off"
              id="email"
              type="text"
              v-model="newPatientForm.email"
              class="form-control"
              placeholder="E-mail do paciente"
              ref="patient_email"
              :class="{ error: errors.patient_email }"
            />
            <div
              v-if="errors.patient_email"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="cellphone">Celular <span class="help" v-if="!requiredField.patient_cellphone"> (opcional)</span></label>
            <input
              autocomplete="off"
              id="cellphone"
              type="text"
              v-model="newPatientForm.cellphone"
              class="form-control"
              placeholder="(00) 00000-0000"
              v-mask="['(##) ####-####', '(##) #####-####']"
              ref="patient_cellphone"
              :class="{ error: errors.patient_cellphone }"
            />
            <div
              v-if="errors.patient_cellphone"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="form-group">
            <label for="cpf">CPF <span class="help" v-if="!requiredField.patient_cpf"> (opcional)</span></label>
            <input
              autocomplete="off"
              id="cpf"
              type="text"
              v-model="newPatientForm.cpf"
              class="form-control"
              placeholder="000.000.000-00"
              v-mask="'###.###.###-##'"
              @change="validateCPF(newPatientForm.cpf)"
              ref="patient_cpf"
              :class="{ error: errors.patient_cpf }"
            />
            <div
              v-if="errors.patient_cpf"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="mother_name">
              Nome da mãe <span class="help" v-if="!requiredField.mother_name"> (opcional)</span>
            </label>
            <input
              autocomplete="off"
              id="mother_name"
              type="text"
              v-model="newPatientForm.mother_name"
              class="form-control"
              @input="validateName('mother_name')"
              placeholder=""
              ref="patient_mother_name"
              :class="{ error: errors.patient_mother_name }"
            />
            <div
              v-if="errors.patient_mother_name"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="cns"
              >Cartão Nacional de Saúde (CNS) <span class="help" v-if="!requiredField.patient_cns"> (opcional)</span></label
            >
            <input
              autocomplete="off"
              id="cns"
              type="text"
              v-model="newPatientForm.cns"
              class="form-control"
              placeholder="000000000000000"
              ref="patient_cns"
              :class="{ error: errors.patient_cns }"
            />
            <div
              v-if="errors.patient_cns"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-25">
        <b-col>
          <div class="form-group">
            <label for="healthPlan">
              Convênio <span class="help" v-if="!requiredField.patient_healthplan"> (opcional)</span>
            </label>
            <multiselect
              id="healthPlan"
              v-model="newPatientForm.healthPlan"
              :options="healthPlans"
              track-by="id"
              label="fantasy_name"
              :showLabels="false"
              :internal-search="true"
              placeholder="Selecione o convênio"
              class="search-mode with-border"
              ref="patient_healthplan"
              :class="{ error: errors.patient_healthplan }"
            >
              <template slot="caret">
                <div class="search">
                  <v-search />
                </div>
              </template>

              <template slot="noOptions"
                >Pesquise pelo nome ou código do convênio</template
              >
              <template slot="noResult">Nenhum convênio encontrado</template>
            </multiselect>
            <div
              v-if="errors.patient_healthplan"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="form-group">
            <label for="plan">Plano <span class="help" v-if="!requiredField.patient_plan"> (opcional)</span></label>
            <multiselect
              id="plan"
              v-model="newPatientForm.plan"
              :options="patientPlans"
              track-by="value"
              label="name"
              :showLabels="false"
              :loading="isLoading"
              :internal-search="false"
              @search-change="debounceSearchPlan"
              placeholder="Buscar plano"
              class="search-mode with-border"
              ref="patient_plan"
              :class="{ error: errors.patient_plan }"
            >
              <template slot="caret">
                <div class="search">
                  <v-search />
                </div>
              </template>

              <template slot="noOptions"
                >Pesquise pelo nome ou código do plano</template
              >
              <template slot="noResult">Nenhum plano encontrado</template>
            </multiselect>
            <div
              v-if="errors.patient_plan"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="form-group">
            <label for="token">
              Número da carteirinha <span class="help" v-if="!requiredField.patient_registration"> (opcional)</span>
            </label>
            <input
              autocomplete="off"
              id="token"
              v-model="newPatientForm.registration"
              type="text"
              ref="patient_registration"
              class="form-control"
              :class="{ error: errors.patient_registration }"
            />
            <div
              v-if="errors.patient_registration"
              class="custom-invalid-feedback"
            >
              Campo obrigatório
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mt-4 text-right">
        <b-button variant="primary" @click="savePatient">
          Cadastrar e voltar para agendamento
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { debounce } from 'lodash'
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import Search from '@/assets/icons/search.svg'
import { cpf } from 'cpf-cnpj-validator'
import moment from 'moment'

export default {
  components: {
    'v-close': Close,
    'v-chevron-down': ChevronDown,
    'v-search': Search
  },
  props: {},
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      newPatientForm: this.getDefaultProps(),
      validated: false,
      isLoading: false,
      healthPlans: [],
      patientPlans: [],
      genderOptions: [
        { value: 'M', name: 'Masculino' },
        { value: 'F', name: 'Feminino' }
      ],
      touched: false,
      existCpf: false,
      isValidBirthday: false,
      requiredField: {},
      errors: {},
    }
  },
  mounted() {
    this.eventHub.$on(
      'new-patient-name',
      searchTerm => (this.newPatientForm.name = searchTerm)
    )
    this.getClinicSettings();
  },
  created() {
    this.debounceSearchPlan = debounce(this.searchPlan, 500)
  },
  methods: {
    moment,
    onShow() {
      this.validated = false
      this.getClinicHealthPlans()
    },
    async onHidden() {
      this.newPatientForm = this.getDefaultProps()
    },
    getDefaultProps() {
      return {
        name: '',
        birthday: '',
        gender: '',
        email: '',
        cellphone: '',
        mother_name: '',
        cpf: '',
        healthPlan: null,
        plan: null,
        registration: ''
      }
    },
    isValid() {
      this.validated = true

      return (
        this.newPatientForm.name &&
        this.newPatientForm.birthday &&
        this.isValidBirthday
      )
    },
    validateName(target) {
      /*
      Match a single character present in the list below [\p{L}\p{M}\s]
      * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
      \p{L} matches any kind of letter from any language
      \p{M} matches a character intended to be combined with another character (e.g. accents, umlauts, enclosing boxes, etc.)
      \s matches any kind of invisible character (equivalent to [\p{Z}\h\v])
      Global pattern flags 
      g modifier: global. All matches (don't return after first match)
      u modifier: unicode. Pattern strings are treated as UTF-16. Also causes escape sequences to match unicode characters
      */
      const regex = /^[\p{L}\p{M}\s]*$/gu
      if (!regex.test(this.newPatientForm[target])) {
        this.$toast.warning('Não é possível inserir caracter especial')
        this.newPatientForm[target] = this.newPatientForm[target].replace(
          /[^\p{L}\p{M}\s]/gu,
          ''
        )
      }
    },
    validateBirthday() {
      this.isValidBirthday = true
      const dateFormat = 'DD/MM/YYYY'
      const strictMode = true

      const validDate = moment(
        this.newPatientForm.birthday,
        dateFormat,
        strictMode
      ).isValid()
      const convertedDate = moment(this.newPatientForm.birthday, dateFormat)
      const today = moment()
      const differenceInYears = today.diff(convertedDate, 'years')

      if (moment(convertedDate).isAfter(today)) {
        this.isValidBirthday = false
      }
      if (!validDate && this.newPatientForm.birthday === '') {
        this.isValidBirthday = false
      }
      const ageOfOldestPersonInHistory = 122
      if (differenceInYears > ageOfOldestPersonInHistory) {
        this.isValidBirthday = false
      }
    },  
    validateCPF(cpf) {
      this.api
        .validateCpf(this.clinic.id, cpf)
        .then(res => {
          this.existCpf = cpf.length && res.data
          if (this.existCpf) {
            this.$toast.warning('O CPF informado já existe em sua clínica')
          }
        })
        .catch(error => {
          this.$toast.error(error.message)
        })
    },
    getData() {
      const personHealthPlan = {
        registration: this.newPatientForm.registration || null,
        health_plan_id: this.newPatientForm.healthPlan
          ? this.newPatientForm.healthPlan.id
          : null,
        plan_id: this.newPatientForm.plan
          ? this.newPatientForm.plan.value
          : null
      }

      return {
        name: this.newPatientForm.name,
        birthday: this.newPatientForm.birthday
          ? moment(this.newPatientForm.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
        gender: this.newPatientForm.gender.value,
        email: this.newPatientForm.email,
        cellphone: this.newPatientForm.cellphone,
        cpf: this.newPatientForm.cpf,
        mother_name: this.newPatientForm.mother_name,
        cns: this.newPatientForm.cns,
        type: 'PATIENT',
        person_health_plans: this.newPatientForm.healthPlan
          ? [personHealthPlan]
          : []
      }
    },
    isValidForm() {
      const errors = {};

      if (!this.newPatientForm.name) {
        errors.patient_name = "Campo obrigatório";
      }

      if (!this.newPatientForm.birthday) {
        errors.patient_birthday = "Campo obrigatório";
      }

      if (this.newPatientForm.birthday && !this.isValidBirthday) {
        errors.patient_birthday = "Insira uma data de nascimento válida";
      }

      if(!this.newPatientForm?.healthPlan?.id && this.requiredField.patient_healthplan){
        errors.patient_healthplan = "Campo obrigatório";
      }

      Object.keys(this.requiredField).map(item => {
        if(this.$refs[item] && (!this.$refs[item].value || this.$refs[item].value.length === 0) && this.requiredField[item]){
          errors[item] = "Campo obrigatório";
        }
      });

      this.errors = errors;
      return Object.keys(errors).length;
    },
    async savePatient() {
      if (this.isValidForm()) {
        this.$toast.warning('Preencha todos os campos obrigatórios.')
        return
      }

      const data = this.getData()
      const isLoading = this.$loading.show()
      try {
        if (this.existCpf && data.cpf) {
          isLoading.hide()
          this.$toast.error('O CPF informado já existe em sua clínica')
          return
        }
        if (this.newPatientForm.cpf && !cpf.isValid(this.newPatientForm.cpf)) {
          this.validated = true
          return
        }
        const res = await this.api.quickRegistrationClinicPatients(
          this.clinic.id,
          data
        )
        this.$emit('create-patient', res.data)
        this.eventHub.$emit('selected-patient', res.data)
        this.$bvModal.hide('new-patient-modal')
      } catch (err) {
        console.log('savePatient: ', err)
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    async getClinicHealthPlans() {
      const res = await this.api.getAllClinicHealthPlans(this.clinic.id)
      this.healthPlans = res.data.map(
        clinicHealthPlan => clinicHealthPlan.health_plan
      )
    },
    searchPlan(query) {
      if (this.newPatientForm.healthPlan !== null && query.length > 0) {
        this.patientPlans = []
        this.api
          .searchPlan(this.newPatientForm.healthPlan.id, query)
          .then(res => {
            const resData = res.data
            this.patientPlans = resData.map(plan => {
              return {
                value: plan.id,
                ans: plan.ans,
                name: plan.name
              }
            })
          })
          .catch(err => {
            console.log('Err', err)
          })
      }
    },
    async getClinicSettings() {
      await this.api
        .getClinicSettings(this.clinic.id)
        .then(res => {
          const { data } = res;

          if(data.quick_registration_fields){
            Object.values(data.quick_registration_fields).map(group => {
              Object.keys(group).map((field)  => {
                this.requiredField[`${field}`] = group[field];
              });
            });
          }

        })
        .catch(err => {
          console.log('Falha ao buscar configurações da clínica!', err)
        })
    },
  }
}
</script>
<style lang="scss">
#new-patient-modal {
  .modal-body {
    padding: 0 !important;

    .header {
      padding: 24px;
      border-bottom: 1px solid var(--neutral-200);

      .title {
        font-family: 'Nunito Sans';
        font-weight: 600;
        font-size: 18px;
        margin: 0;
        color: #525c7a;
      }

      svg {
        width: 24px;
        height: 24px;
        fill: var(--dark-blue);
      }
    }

    .body {
      padding: 24px;
    }
  }

  .from-control .help {
    margin-left: 0.2rem;
  }
}
</style>
